import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

export const Logo = () => {
  const { portfolioId } = useParams();
  const navigate = useNavigate();
  return (
    <div className="max-w-[120px] md:max-w-[150px] overflow-hidden">
      <div className="w-40 md:w-52 rounded cursor-pointer" onClick={() => navigate(portfolioId ? `/portfolio/${portfolioId}/overview` : '/overview')}>
        <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="logo" />
      </div>
    </div>
  );
};
