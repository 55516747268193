import { lazy, Suspense, ReactNode } from 'react';
import { LoadingIndicator, Logo, PortfolioGuard, PortfolioSelect } from 'components';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { useGetPortfolioOptions } from 'hooks/useGetPortfolioOptions';
import { useMatchesBreakpoint } from 'hooks/useMatchesBreakpoint';
import { PortfolioOption } from 'layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeader/PortfolioNavigationHeader';
import { useNavigateToPortfolioTab } from 'layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeader/useNavigateToPortfolioTab';
import { AiFillHome as HomeIcon } from 'react-icons/ai';
import { FaBook as HoldingsIcon, FaListUl as TransactionsIcon } from 'react-icons/fa';
import { GoGraph as TradingIcon } from 'react-icons/go';
import { Navigate, NavLink, Outlet, Route, Routes, useParams } from 'react-router-dom';

const Overview = lazy(() => import('./overview').then((module) => ({ default: module.OverviewPage })));
const PortfolioOverview = lazy(() => import('./portfolio/overview').then((module) => ({ default: module.OverviewPage })));

const Holdings = lazy(() => import('./holdings').then((module) => ({ default: module.HoldingsPage })));
const PortfolioHoldings = lazy(() => import('./portfolio/holdings').then((module) => ({ default: module.HoldingsView })));
const PortfolioHolding = lazy(() => import('./portfolio/holdings/[holdingId]').then((module) => ({ default: module.HoldingPage })));

const Holding = lazy(() =>
  import('./holdings/[holdingId]').then((module) => ({
    default: module.HoldingPage,
  }))
);
const Transactions = lazy(() =>
  import('./transactions').then((module) => ({
    default: module.TransactionsPage,
  }))
);
const TransactionDetails = lazy(() =>
  import('./transactions/[transactionId]').then((module) => ({
    default: module.TransactionDetailsPage,
  }))
);
const PortfolioTransactions = lazy(() =>
  import('./portfolio/transactions').then((module) => ({
    default: module.TransactionsPage,
  }))
);
const PortfolioTransactionDetails = lazy(() =>
  import('./portfolio/transactions/[transactionId]').then((module) => ({
    default: module.TransactionDetailsPage,
  }))
);

const Orders = lazy(() => import('./orders').then((module) => ({ default: module.OrdersPage })));
const OrderDetails = lazy(() =>
  import('./orders/[orderId]').then((module) => ({
    default: module.OrderDetailsPage,
  }))
);
const Documents = lazy(() => import('./documents').then((module) => ({ default: module.DocumentsPage })));
const Contact = lazy(() => import('./contact').then((module) => ({ default: module.ContactPage })));
const Trading = lazy(() => import('./trading').then((module) => ({ default: module.TradingPage })));
const PortfolioTrading = lazy(() => import('./portfolio/trading').then((module) => ({ default: module.TradingPage })));

export const UserWithLinkedContactRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Navigate to="overview" replace />} />
      <Route path="" element={<MainLayout />}>
        <Route path="" element={<NavBar />}>
          <Route
            path="overview"
            element={
              <PortfolioGuard>
                <Overview />
              </PortfolioGuard>
            }
          />
          <Route
            path="transactions"
            element={
              <PortfolioGuard>
                <Transactions />
              </PortfolioGuard>
            }
          />
          <Route
            path="holdings"
            element={
              <PortfolioGuard>
                <Holdings />
              </PortfolioGuard>
            }
          />
          <Route
            path="trading"
            element={
              <PortfolioGuard>
                <Trading />
              </PortfolioGuard>
            }
          />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Route>
        <Route path="transactions/:transactionId" element={<TransactionDetails />} />
        <Route path="holdings/:holdingId" element={<Holding />} />
        <Route path="portfolio/:portfolioId/*">
          <Route path="" element={<Navigate to="overview" replace />} />
          <Route path="" element={<NavBar />}>
            <Route path="overview" element={<PortfolioOverview />} />
            <Route path="holdings" element={<PortfolioHoldings />} />
            <Route path="transactions" element={<PortfolioTransactions />} />
            <Route path="trading" element={<PortfolioTrading />} />
            <Route path="*" element={<h1>Pf View - Not found</h1>} />
          </Route>
          <Route path="holdings/:holdingId" element={<PortfolioHolding />} />
          <Route path="transactions/:transactionId" element={<PortfolioTransactionDetails />} />
        </Route>
      </Route>
    </Routes>
  );
};

const MainLayout = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingIndicator center />}>
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  );
};

const NavBar = () => {
  const portfolioOptions = useGetPortfolioOptions();
  const { portfolioId } = useParams();
  const currentPortfolio = portfolioId ? parseInt(portfolioId, 10) : 0;
  const navigateToPortfolioTab = useNavigateToPortfolioTab();
  const onPortfolioChange = (selectedOption: PortfolioOption) => {
    navigateToPortfolioTab(selectedOption.urlPrefix);
  };
  const isMd = useMatchesBreakpoint('md');
  return (
    <>
      <div className="z-50 fixed flex px-4 sm:px-10 md:px-16 lg:px-32 justify-between md:justify-start items-center top-0 w-full h-16 bg-white border-b border-gray-200 shadow-md md:border-b-0 md:shadow-none">
        <Logo />
        <div className="max-w-[300px]">
          <PortfolioSelect portfolioOptions={portfolioOptions} portfolioId={currentPortfolio} onChange={onPortfolioChange} />
        </div>
      </div>
      <div className="z-10 fixed flex px-4 sm:px-10 md:px-16 lg:px-32 justify-center md:justify-start items-center bottom-0 md:top-16 w-full h-14 md:h-12 bg-white border-t md:border-t-0 md:border-b border-gray-200 md:shadow-md">
        <ul className="p-0 m-0 flex gap-4 children:font-bold">
          <li>
            <NavLink title="overview" to="overview">
              {isMd ? 'Home' : <HomeIcon className="menu-item" />}
            </NavLink>
          </li>
          <li>
            <NavLink title="transactions" to="transactions">
              {isMd ? 'Transactions' : <TransactionsIcon className="menu-item" />}
            </NavLink>
          </li>
          <li>
            <NavLink title="holdings" to="holdings">
              {isMd ? 'Holdings' : <HoldingsIcon className="menu-item" />}
            </NavLink>
          </li>
          <li>
            <NavLink title="trading" to="trading">
              {isMd ? 'Trading' : <TradingIcon className="menu-item" />}
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="fixed h-6 w-full bottom-14 sharp md:hidden"></div>
      <div className="h-full mb-14 md:mb-0 mt-16 md:mt-28 py-4 text-gray-900 bg-gray-50 px-4 sm:px-10 md:px-16 lg:px-32">
        <Outlet />
      </div>
    </>
  );
};
